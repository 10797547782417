import React from 'react';
import { useNavigate } from "react-router-dom";


const CaptureLink = () => {
    const navigate = useNavigate();

    /**
     * Click event handler for the links to the react components generated from the blazor component.
     * When the user clicks on a link to one of the "saved/" routes, the user is navigated programmatically to the the route using react router. 
     * @param event the document click event.
     */
    const documentClickLinkHandler = (event: MouseEvent) => {
        // Check if we clicked on a link that contains a href
        var target = event.target as HTMLElement;
        if (target.tagName !== 'A' || !target.hasAttribute('href')) {
            return;
        }
        let url = target.getAttribute('href');

        // Check if the url does not start with /saved/ or saved/
        if (url === undefined || url === null || !(url.startsWith("saved/") || url.startsWith("/saved/"))) {
            return;
        }

        // We are dealing with a link to one of the links inside the react. Stop full reload and use react to navigate to the component.
        event.preventDefault();
        event.stopPropagation();


        // Make sure the url starts with a slash.
        if (!url?.startsWith("/")) {
            url = "/" + url;
        }

        navigate(url);
    }

    // Register the document event listener in the React.useEffect so the event are added after the page is loaded.
    React.useEffect(() => {
        document.addEventListener("click", documentClickLinkHandler, false);

        // cleanup this component
        return () => {
            document.removeEventListener("click", documentClickLinkHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (null);
}

export default CaptureLink;