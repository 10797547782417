import * as React from "react";

export interface Props {
    isSearching?: boolean;
}

interface State {
    isSearching: boolean;
}

class RefineButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isSearching: (props.isSearching || false) };
    }

    componentDidMount() {
        document.addEventListener('mylexsearchcompleted', (event) => {
            this.updateState((event as CustomEvent).detail.isSearching);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mylexsearchcompleted', (event) => {
            this.updateState((event as CustomEvent).detail.isSearching);
        });
    }

    render() {
        if (this.state.isSearching) {
            return (
                <div id="aggregation-actions">
                    <button id="toggle-aggregations" className="btn btn-refine d-md-inline-block d-none font-weight-bold text-size-small" type="button">
                        <i className="icon-funnel font-weight-bold align-middle mr-1"></i>
                        <span id="showHideText" data-text-hide="Verberg verfijningen" data-text-show="Toon verfijningen">Verberg verfijningen</span>
                    </button>
                    <span id="toggle-aggregations-small" className="btn btn-refine text-size-small font-weight-bold d-inline-block d-md-none" aria-expanded="true" data-toggle="offcanvas" data-target="#aggregations">
                        <i className="icon-funnel font-weight-bold align-middle mr-1"></i>
                        <span id="showHideTextSmall">Verfijnen</span>
                    </span>

                </div>
            );
        } else {
            return null;
        }
    }

    updateState(isSearching: boolean) {
        this.setState({ isSearching: isSearching });
    }
}

export default RefineButton;