import { useParams } from "react-router-dom";

const CategoryCollections = () => {
    const { category } = useParams();
    const params = `{'stand-alone-component':false, category:'${category}'}`;
    return (
        <>
            <mylex-component params={params} type="categorycollections"></mylex-component>
        </>
    );
}

export default CategoryCollections;