import { NavLink } from "react-router-dom";

import * as React from "react";

class Header extends React.Component {

    render() {

        return (
            <>
                <header id="page_header" className="container-fluid sticky-top bg-bhic">
                    <div className="row border-bottom">
                        <div className="col">
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1 nav-height">
                                    <nav className="navbar navbar-expand navbar-light bg-white menu-main px-0">
                                        <NavLink className="navbar-brand" to="/" >
                                            <img src={process.env.PUBLIC_URL + '/img/MY-LEX-View.svg'} height="26" alt="logo MY-LEX Suite"></img>
                                        </NavLink>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="row justify-content-center d-none m-0" id="topbarButtons">
                    <div className="col-12 col-lg-6">
                        <div className="position-fixed clearfix" style={{ top: "80px", zIndex: 1021 }}>
                            <NavLink className={(navData) => (navData.isActive ? "btn btn-mylex-gray btn-sm mr-2 px-3 text-uppercase" : "btn btn-secondary btn-sm mr-2 px-3 text-uppercase")} to="/" end>Zoeken</NavLink>
                            <NavLink className={(navData) => (navData.isActive ? "btn btn-mylex-gray btn-sm mr-2 px-3 text-uppercase" : "btn btn-secondary btn-sm mr-2 px-3 text-uppercase")} to="/saved/collections" end>Bewaard</NavLink>
                            <NavLink className={(navData) => (navData.isActive ? "btn btn-mylex-gray btn-sm mr-2 px-3 text-uppercase" : "btn btn-secondary btn-sm mr-2 px-3 text-uppercase")} to="/recent/search" end>Recent</NavLink>
                        </div>
                    </div>
                </div>
                {/*To compensate for the height of the topbarButtons*/}
                <div className="pb-4 mb-4"> </div>
                <mylex-component type="notifier"></mylex-component>
            </>
        );

    }
};

export default Header;