
const RecentDocuments = () => {
    return (
        <>
            <mylex-component type="recentdocuments"></mylex-component>
            <mylex-component type="document"></mylex-component>
        </>
    );
}

export default RecentDocuments;