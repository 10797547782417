import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

// Form values to post
type Values = {
    documentId: string,
    firstname: string,
    lastname: string,
    email: string,
    remarks: string,
}

// Result after post
type DocumentRequestResponse = {
    formPostResponseMessage: string,
    ok: boolean,
    messageClass: string,
}

/** 
 *  Model dialog that will be displayed when the user clicks a data-document-request link.
 **/
const DocumentRequestModal = () => {
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formAction, setFormAction] = useState("");
    const [isFormPostDisabled, setFormPostDisabled] = useState(false);

    const documentIdQueryStringKey = "document-request-id";

    /**
     * Resets the state of the controll back to the initial values.
     * */
    const resetState = () => {
        setValues({
            documentId: "",
            firstname: "",
            lastname: "",
            email: "",
            remarks: ""
        });

        setDocumentRequestResponse({
            formPostResponseMessage: "",
            messageClass: "",
            ok: false
        });

        setValidated(false);
        setFormAction("");
        setFormPostDisabled(false);
    }

    const handleClose = () => {
        setShow(false);
        resetState();
    }
    const handleShow = () => setShow(true);

    const [values, setValues] = useState<Values>({
        documentId: "",
        firstname: "",
        lastname: "",
        email: "",
        remarks: ""
    });

    const [documentRequestResponse, setDocumentRequestResponse] = useState<DocumentRequestResponse>({
        formPostResponseMessage: "",
        messageClass: "",
        ok: false
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    }

    var documentRequestForm = React.createRef<HTMLFormElement>();

    /**
     * Click event handler for the document.
     * When the user clicks on an document request link, the modal dialog will shown.
     * 
     * Example link: <a href="http://mlt-mlp4.mlt.local/web/requestdocument/PostDocument/?document-request-id=123456789">Request document deeplink</a>
     * 
     * @param event the document click event
     */
    const documentClickLinkHandler = (event: MouseEvent) => {

        // Check if we clicked on a link that contains a href
        var target = event.target as HTMLElement;
        if (target.tagName !== 'A' || !target.hasAttribute('href')) {
            return;
        }

        // Check if the url does not start with #
        if (target.getAttribute('href')?.startsWith("#"))
            return;

        try {
            // Check if the href contains a query string param "document-request-id" 
            const url = new URL(target.getAttribute('href') ?? "");

            if (url.searchParams.get(documentIdQueryStringKey) == null)
                return;

            // We are dealing with a document request link, stop default behaviour, and deal with the click.
            event.preventDefault();
            event.stopPropagation();

            // Get the required data properties from the link.
            setValues({ ...values, documentId: url.searchParams.get(documentIdQueryStringKey) ?? "" });

            // Set the form action to the url in the href, but withouth the query string param.
            setFormAction(url.href.replace(url.search, ""));

            // Display document request form modal dialog.
            handleShow();

        } catch (error) {
            console.log(error);
            return;
        }
    }

    // Register the document event listener in the React.useEffect so the event are added after the page is loaded.
    React.useEffect(() => {
        document.addEventListener("click", documentClickLinkHandler, false);

        // cleanup this component
        return () => {
            document.removeEventListener("click", documentClickLinkHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    /**
     * Create the document request and sends it to the configured formAction.
     */
    const sendDocumentRequest = () => {
        fetch(formAction,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }
        ).then((res) => {
            //console.log(res);
            if (res.ok) {
                setFormPostDisabled(true);
                setDocumentRequestResponse({
                    formPostResponseMessage: "",
                    messageClass: "text-success",
                    ok: res.ok
                });
            } else {
                throw Error(res.statusText);
            }
        }).catch((error) => {
            //console.log(error);
            setDocumentRequestResponse({
                formPostResponseMessage: "Document aanvraag is niet gelukt: " + error,
                messageClass: "text-danger mb-3",
                ok: false
            });

        });
    }

    /**
     * The form submit handler trigger. 
     * Will validate the form and sends the document request to the configured destination.
     * 
     * @param event the button click event.
     */
    const submitFormHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log(JSON.stringify(values));

        // Validation failed
        event.preventDefault();
        event.stopPropagation();

        if (documentRequestForm.current?.checkValidity() === false) {

            setValidated(true);
        }
        else {
            // Validation succeeded
            setValidated(true);
            sendDocumentRequest();
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Document aanvragen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-secondary">Om dit document in te zien kun je de onderstaande gegevens invullen.<br/><br/>Nadat je op de knop 'Document aanvragen' hebt gedrukt krijg je via e-mail direct een link toegestuurd om het document te downloaden.</div>
                <Form noValidate validated={validated} ref={documentRequestForm}>
                    <Form.Group className="mb-3" controlId="requestForm.Firstname">
                        <Form.Label className="h5">Voornaam</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={handleChange}
                            value={values.firstname}
                            name="firstname"
                            autoFocus
                        />
                        <Form.Control.Feedback type="invalid">Veld is verplicht.</Form.Control.Feedback>

                        <Form.Control
                            type="hidden"
                            value={values.documentId}
                            name="documentId"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="requestForm.Lastname">
                        <Form.Label className="h5">Achternaam</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={handleChange}
                            value={values.lastname}
                            name="lastname"

                        />
                        <Form.Control.Feedback type="invalid">Veld is verplicht.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="requestForm.Email">
                        <Form.Label className="h5">E-mailadres</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                        />
                        <Form.Control.Feedback type="invalid">Veld is verplicht en dient een geldig e-mailadres te zijn.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Remarks"
                    >
                        <Form.Label className="h5">Opmerking</Form.Label>
                        <Form.Control as="textarea"
                                      rows={3}
                                      onChange={handleChange}
                                      value={values.remarks}
                                      name="remarks"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className={documentRequestResponse.messageClass}>{documentRequestResponse.formPostResponseMessage}</div>

                {!documentRequestResponse.ok &&
                    <div>
                        <Button variant="secondary" onClick={handleClose} className="mr-2">
                            Annuleren
                        </Button>

                        <Button variant="primary" onClick={submitFormHandler} disabled={isFormPostDisabled}>
                            Document aanvragen
                        </Button>
                    </div>
                }

                {documentRequestResponse.ok &&
                    <div>
                        <Button variant="secondary" onClick={handleClose}>
                            <i className="text-success icon-check-circle-fill"></i> Sluiten
                        </Button>
                    </div>
                }

            </Modal.Footer>

        </Modal>
    );
}

export default DocumentRequestModal;