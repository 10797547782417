import * as React from "react";

class Footer extends React.Component {

    render() {

            return (
                <div className="page-footer container-fluid">
                    <div className="row">
                        <div className="col-md-10 col-sm-12 col-xs-12 offset-md-1 text-right">
                            <p className="small text-mylex-gray">
                                <mylex-component type="version"></mylex-component>
                            </p>
                        </div>
                    </div>
                </div>
            );

    }
};

export default Footer;