import { Outlet, NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from '../components/Header';
import CaptureLink from '../components/CaptureLink';
const LayoutSaved = () => {
    return (
        <>
            <Header />
            <CaptureLink />
            <div className="row m-0" id="savedPageSidebar">

                <div className="col-2 offset-1 d-none d-lg-block">
                    <div className="position-fixed pt-5">
                        <div className="text-mylex-gray header-uppercase pb-3 font-weight-bold">
                            Bewaard
                        </div>
                        <div className="text-size-x-small font-weight-bold pl-2 text-uppercase">
                            <NavLink id="collectionsLink" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900 ") + " collections-link"} to="/saved/collections">Collecties</NavLink>
                            <NavLink id="mappingsLink" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900 ") + " collections-link"} to="/saved/mappings">Mappings</NavLink>
                            <NavLink id="savedQueriesLink" className={(navData) => (navData.isActive ? "section-item pb-3 d-block" : "section-item pb-3 d-block text-gray-900")} to="/saved/search">Zoekopdrachten</NavLink>
                            <NavLink id="savedDocumentsLink" className={(navData) => (navData.isActive ? "section-item pb-3 d-block" : "section-item pb-3 d-block text-gray-900")} to="/saved/documents">Documenten</NavLink>
                        </div>
                    </div>
                </div>

                <div className="row d-lg-none d-block w-100">
                    <div className="col-12 ml-3 mt-5">
                        <div className="dropdown">
                            <button type="button" className="btn bg-white btn-secondary dropdown-toggle-outline btn-lg w-100 text-left" data-toggle="dropdown">
                                Bewaard
                            </button>
                            <div className="dropdown-menu bg-white w-100" aria-labelledby="dropdownSections">
                                <NavLink id="collectionsLinkMobile" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900") + " collections-link"} to="/saved/collections">Collecties</NavLink>
                                <NavLink id="mappingsLinkMobile" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900 ") + " collections-link"} to="/saved/mappings">Mappings</NavLink>
                                <NavLink id="savedQueriesLinkMobile" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900")} to="/saved/search">Zoekopdrachten</NavLink>
                                <NavLink id="savedDocumentsLinkMobile" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900")} to="/saved/documents">Documenten</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-8">
                    <Outlet />
                </div>
            </div>

            <Footer />
        </>
    )
};

export default LayoutSaved;