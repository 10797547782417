import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from '../components/Header';

const Layout = () => {
    return (
        <>
            <div className="page-container bg-lines-light">
                <Header />
                <div className="page-content">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
};

export default Layout;