import { useParams } from "react-router-dom";

const CollectionItem = () => {
    const { categoryname, collectionid, id } = useParams();
    const params = `{'stand-alone-component':false, 'category-name':'${categoryname}', 'collection-id':'${collectionid}', 'collection-item-id':'${id}'}`;
    return (
        <>
            <mylex-component params={params} type="collectionitem"></mylex-component>
        </>
    );
}

export default CollectionItem;