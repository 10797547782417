import { Outlet, NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from '../components/Header';
import CaptureLink from '../components/CaptureLink';
const LayoutRecent = () => {
    return (
        <>
            <Header />
            <CaptureLink />
            <div className="row m-0" id="recentPageSidebar">

                <div className="col-2 offset-1 d-none d-lg-block">
                    <div className="position-fixed pt-5">
                        <div className="text-mylex-gray header-uppercase pb-3 font-weight-bold">
                            Recent
                        </div>
                        <div className="text-size-x-small font-weight-bold pl-2 text-uppercase">
                            <NavLink id="recentQueriesLink" className={(navData) => (navData.isActive ? "section-item pb-3 d-block" : "section-item pb-3 d-block text-gray-900")} to="/recent/search">Zoekopdrachten</NavLink>
                            <NavLink id="recentDocumentsLink" className={(navData) => (navData.isActive ? "section-item pb-3 d-block" : "section-item pb-3 d-block text-gray-900")} to="/recent/documents">Documenten</NavLink>
                        </div>
                    </div>
                </div>

                <div className="row d-lg-none d-block w-100">
                    <div className="col-12 ml-3 mt-5">
                        <div className="dropdown">
                            <button type="button" className="btn bg-white btn-secondary dropdown-toggle-outline btn-lg w-100 text-left" data-toggle="dropdown">
                                Recent
                            </button>
                            <div className="dropdown-menu bg-white w-100" aria-labelledby="dropdownSections">
                                <NavLink id="recentQueriesLinkMobile" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900")} to="/recent/search">Zoekopdrachten</NavLink>
                                <NavLink id="recentDocumentsLinkMobile" className={(navData) => (navData.isActive ? "section-item pb-3 d-none" : "section-item pb-3 d-none text-gray-900")} to="/recent/documents">Documenten</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-8">
                    <Outlet />
                </div>
            </div>

            <Footer />
        </>
    )
};

export default LayoutRecent;