import { useParams } from "react-router-dom";

const CollectionItems = () => {
    const { categoryname } = useParams();
    const { id } = useParams();
    const params = `{'stand-alone-component':false, 'category-name':'${categoryname}', 'collection-id':'${id}'}`;
    return (
        <>
            <mylex-component params={params} type="collectionitems"></mylex-component>
        </>
    );
}

export default CollectionItems;