import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Search from './pages/Search';
import Categories from './pages/Categories';
import AddNewCollection from './pages/AddNewCollection';
import CategoryCollections from './pages/CategoryCollections';
import CollectionItems from './pages/CollectionItems';
import CollectionItem from './pages/CollectionItem';
import Mappings from './pages/Mapppings';
import SavedQueries from './pages/SavedQueries';
import SavedDocuments from './pages/SavedDocuments';
import RecentQueries from './pages/RecentQueries';
import RecentDocuments from './pages/RecentDocuments';

// Layouts
import Layout from "./Layouts/Layout";
import LayoutSaved from "./Layouts/LayoutSaved";
import LayoutRecent from "./Layouts/LayoutRecent";

// Theme
import "./themes/bootstrap4_mylex/theme.scss";

const App = () => (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>           
            <Route path='/saved/' element={<LayoutSaved />} >
                <Route index element={<Categories />} />
                <Route path='collections' element={<Categories />} />
                <Route path='mappings' element={<Mappings />} />
                <Route path='search' element={<SavedQueries />} />
                <Route path='documents' element={<SavedDocuments />} />
                <Route path='add-new-collection' element={<AddNewCollection />} />
                <Route path='category-collections/:category' element={<CategoryCollections  />} />
                <Route path='collection-items/:categoryname/:id' element={<CollectionItems />} />
                <Route path='collection-item/:categoryname/:collectionid/:id' element={<CollectionItem />} />
                <Route path="*" element={<Categories />} />
            </Route>
            <Route path='/recent/' element={<LayoutRecent />} >
                <Route index element={<RecentQueries />} />
                <Route path='search' element={<RecentQueries />} />
                <Route path='documents' element={<RecentDocuments />} />
                <Route path="*" element={<RecentQueries />} />
            </Route>
            <Route path='/' element={<Layout />} >
                <Route index element={<Search />} />
                <Route path='search' element={<Search />} />
                <Route path="*" element={<Search />} />
            </Route>
        </Routes>
    </BrowserRouter>
);
export default App;