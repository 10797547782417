import React, { useState } from 'react';
import DocumentRequestModal from "../components/DocumentRequestModal";

// Import components
import RefineButton from '../components/RefineButton';

const Search = () => {
    const [loading, setLoading] = useState(true);
    const [loadingmylex, setLoadingMyLex] = useState(true);

    /**
     * Event handler for the mylexsearchready event.
     */
    const mylexSearchReady = () => {
        console.log("MY-LEX search is ready!");

        setLoadingMyLex(false);

        // Remove the event lister when we are done.
        document.removeEventListener('mylexsearchready', mylexSearchReady);
    }

    // Register the document event listener in the React.useEffect so the event are added after the page is loaded.
    React.useEffect(() => {
        // this simulates an async action, after which the component will render the content
        document.addEventListener('mylexsearchready', mylexSearchReady, false);

        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {

        // When the react app is still loading, we return null. this will trigger the loader "sk-cube-grid-1" in index.html to be shown.
        return null;
    } else {

        const Loader = () => {
            if (loadingmylex) {
                return (
                    <>
                        <div className="modal-backdrop fade"></div>
                        <div className="modal fade show d-block">
                            <div className="modal-dialog">
                                <div className="loader loading">
                                    <div className="sk-cube-grid-2">
                                        <div className="sk-cube sk-cube1"></div>
                                        <div className="sk-cube sk-cube2"></div>
                                        <div className="sk-cube sk-cube3"></div>
                                        <div className="sk-cube sk-cube4"></div>
                                        <div className="sk-cube sk-cube5"></div>
                                        <div className="sk-cube sk-cube6"></div>
                                        <div className="sk-cube sk-cube7"></div>
                                        <div className="sk-cube sk-cube8"></div>
                                        <div className="sk-cube sk-cube9"></div>
                                    </div>
                                </div>
                            </div>
                               
                        </div>
                    </>
                );
            }
            return (<div></div>);
        }

        return (
            <>
                <Loader />
                        <section className="container-fluid flex-grow d-flex flex-column">
                            <div className="row search-box">
                                <div className="col-md-6 col-sm-12 col-xs-12 offset-md-3">
                                    <mylex-component type="searchbar" params="{'searchPlaceholder':'Zoek bijvoorbeeld op plaats, straat en huisnummer'}"></mylex-component>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <mylex-component type="searchfeedback"></mylex-component>
                                        </div>
                                    </div>

                                    <div className="row no-gutters mb-3 align-items-start">
                                        <div className="col-6 col-lg-2 mb-1 order-2 order-lg-1">
                                            <RefineButton />
                                        </div>

                                        <div className="col-12 col-lg-8 mb-2 pl-lg-2 mb-lg-0 order-1 order-lg-2">
                                            <mylex-component type="searchentrytags"></mylex-component>
                                            <mylex-component type="aggregationtags"></mylex-component>
                                        </div>

                                        <div className="col-6 col-lg-2 order-3">
                                            <mylex-component type="sorting"></mylex-component>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <div className="row" id="row-main">

                                        <div id="aggregations" className="col-12 col-md-3 col-lg-2 navmenu-default navmenu-fixed-left-xs offcanvas-xs " data-placement="left">
                                            <div className="col-12 p-0">
                                                <mylex-component type="savequery"></mylex-component>
                                            </div>
                                            <div className="aggregations-header d-block d-md-none d-flex justify-content-between align-items-center border-bottom">
                                                <h1 className="text-mylex-gray text-size-medium lead header-uppercase">Refine</h1>
                                                <h1><button type="button" className="navbar-toggle btn btn-link text-size-large" data-toggle="offcanvas" data-target="#aggregations"><i className="icon-x"></i></button></h1>
                                            </div>
                                            <div className="aggregations-body">
                                                <mylex-component type="aggregations"></mylex-component>
                                            </div>
                                        </div>
                                        <div id="results" className="col">

                                            <mylex-component type="resultlist"></mylex-component>
                                            <mylex-component type="pagination"></mylex-component>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                        <mylex-component type="document"></mylex-component>
                        <DocumentRequestModal />                   
            </>
        );
    }
}

export default Search;